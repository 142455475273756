import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/Layout";

const IndexPage = (props) => {
  return (
    <Layout location={props.location}>
      <div id="index" className="page">
        <Helmet>
          <title>Bedachungen und Gerüstbau - Hans Klopfenstein AG </title>
          <meta name="robots" content="noindex" />
        </Helmet>
        {/* <Hero /> */}
        <section id="main">
          <div className="container">
            <h2>Geschäfts-Auflösung der Firma Hans Klopfenstein AG </h2>
            <p className="intro">
              Sehr geschätzte Damen und Herren, geschätzte Geschäftskunden. Wir
              möchten Sie informieren, dass die Geschäftstätigkeit der Hans
              Klopfenstein AG per Ende Dezember eingestellt wurde.
            </p>
            <p className="intro">
              Nach gut 42 Jahren bin ich in den verdienten Ruhestand getreten
              und freue mich auf meinen neuen Lebensabschnitt als Pensionär.
            </p>
            <p className="intro">
              Wir bedanken uns an dieser Stelle recht herzlich bei allen Kunden
              für die langjährige Treue und gute Zusammenarbeit in all den
              Jahren.
            </p>
            <p className="intro">
              Herzliche Grüsse
              <br />
              Hans Klopfenstein
            </p>
          </div>
        </section>
        <section id="offer" />
      </div>
    </Layout>
  );
};

export default IndexPage;
